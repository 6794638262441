import { useInView } from 'react-intersection-observer';
import { useAnimation } from "framer-motion";

const useScroll = (props) => {

    let val = props ? props.threshold ? props.threshold : 0.4 : 0.4;

    const controls = useAnimation();
    const [element, isDisplayed ] = useInView({ threshold: val });
    if(isDisplayed) {
        controls.start("end");
    } else {
        controls.start("start");
    }

    return ([element, controls]);
}
 
export default useScroll;