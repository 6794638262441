import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Home = styled(motion.section)`
    height: 100vh;
    display: flex;
    align-items: flex-start;
    position: relative;

    div.container {
        margin-top: 10%;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        transition: 0.3s;

        div, h1, h2, h3, img {
            overflow: hidden;
        }
        
        div.presentation {
            h1 { font-size: 4em; }

            h2 { font-size: 3em; }

            h3 {
                font-size: 2em;
                text-align: right;
            }
        }
    
        .img {
            overflow: hidden;
            max-width: 300px;
            width: 40%;
            margin: 7%;
            border-radius: 50%;
            -webkit-box-shadow: 0px 5px 21px -3px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 5px 21px -3px rgba(0,0,0,0.5);
            box-shadow: 0px 5px 21px -3px rgba(0,0,0,0.5);
            transition: 0.3s;

            &:hover {
                transform: translateY(-10px);
                -webkit-box-shadow: 0px 30px 50px -3px rgba(0,0,0,0.5);
                -moz-box-shadow: 0px 30px 50px -3px rgba(0,0,0,0.5);
                box-shadow: 0px 30px 50px -3px rgba(0,0,0,0.5);
            }
        }

        img.profile {
            width: 100%;
            transform: scale(1.1);
        }
    }

    @media screen and (min-width: 768px) {

        div.container {
            div.presentation {
                h1 { font-size: 5em; }

                h2 { font-size: 3em; }

                h3 { font-size: 2em; }
            }

            .img {
                width: 35%;
                margin: 0 10% 10%;
            }

            img.profile {
                width: 99%;
            }
        }
    }

    @media screen and (min-width: 992px) {

        div.container {
            margin-top: 15%;
            flex-direction: row;

            div.presentation {
                margin: 0px 0px 0px 2vw;
        
                h1 {
                    font-size: 6em;
                }

                h2 {
                    font-size: 3em;
                }

                h3 {
                    font-size: 2em;
                }
            }

            .img {
                width: 30%;
                margin: 0%;
                border-radius: 0px 20px 20px 0px;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        div.container {
            align-items: flex-start;
            justify-content: center;

            .img {
                border-radius: 0px 20px 0px 20px;
            }
        }
    }

    @media screen and (min-width: 1400px) {
        div.container {
            margin-top: 10%;
        }
    }

    @media screen and (min-width: 1900px) {
    }
`;

export const StyledSvg = styled(motion.svg)`
    position: absolute;
    left: -1;
    bottom: 0;
    transform-origin: bottom;
    
    z-index: -1;

    @media screen and (min-width: 768px) {
        
    }
`;