import React, { useState } from 'react';
import { Home, StyledSvg } from '../styles/HomeStyles';
import { motion } from 'framer-motion';
import { fade, rollDown, rollUp } from '../anims/GlobalAnimations';

const HomeSection = () => {

    const titles = ['a web developer', 'a human', 'a proud Mexican', 'an Android dev'];
    const [phrase, setPhrase] = useState('...');
    const [prev, setPrev] = useState(0);

    const getPhrase = () => {
        let randomNumber = 0;
        do {
            randomNumber = Math.floor(Math.random()*(5-1));
        } while(randomNumber === prev);
        
        setPrev(randomNumber);
        setPhrase(titles[randomNumber]);
    }

    const duration = 0.5;

    const icon1 = {
        start: {
            pathLength: 0,
            fill: "rgba(255, 255, 255, 0)"
        },
        end: {
            pathLength: 1,
            fill: "rgba(130, 130, 130, 0.5)"
        }
    };

    const icon2 = {
        start: {
            pathLength: 0,
            fill: "rgba(255, 255, 255, 0)"
        },
        end: {
            pathLength: 1,
            fill: "rgba(58, 58, 58, 0.5)"
        }
    };

    return ( 
        <Home id="home">
            <motion.div variants={fade} 
                custom={duration}
                initial="start" 
                animate="end" 
                className="container">
                <motion.div className="img">
                    <img className="profile" src="./assets/me.jpg" alt="me"/>
                </motion.div>
                <motion.div className="presentation">
                    <motion.h2 variants={rollUp}>Hello, I'm</motion.h2>
                    <motion.h1 variants={rollDown} onClick={getPhrase}>Felipe Pulido</motion.h1>
                    <motion.h3 variants={rollDown}>{phrase}</motion.h3>
                </motion.div>
            </motion.div>
            <StyledSvg viewBox="0 -1 2245 532" xmlns="http://www.w3.org/2000/svg">
                <motion.path 
                    variants={icon1}
                    initial="start"
                    animate="end"
                    transition={{
                        default: { duration: 2, ease: "easeIn" },
                        fill: { duration: 2, ease: "easeInOut" }
                    }}
                    d="M1 242.826C161.833 330.992 277.6 354.426 542 282.826C872.5 193.326 1091 183.825 1332 324.325C1573 464.825 1885 -446.674 2244.5 295.66V531.16H1V242.826Z" 
                    stroke="#828282"
                    stroke-opacity="0.1"/>
            </StyledSvg>
            <StyledSvg viewBox="0 -1 2245 568" xmlns="http://www.w3.org/2000/svg">
                <motion.path 
                    variants={icon2}
                    initial="start"
                    animate="end"
                    transition={{
                        default: { duration: 2, ease: "easeIn" },
                        fill: { duration: 2, ease: "easeInOut" }
                    }}
                    d="M1 386.449C117 327.616 302.373 95.6812 609 237.949C1129.5 479.449 1220.94 172.187 1481 323.449C1723.5 464.5 1917.18 -112.064 2244.5 21.4491V566.949H1V386.449Z" 
                    stroke="#3A3A3A"
                    stroke-opacity="0.5"/>
            </StyledSvg>
        </Home>
    );
}

export default HomeSection;