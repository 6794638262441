import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Contact = styled(motion.section)`
    justify-content: space-between;
    padding: 5rem 5rem;
    height: 90vh;
    position: relative;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
`;

export const GIT = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h3 {
        flex: 1;
        font-size: 5rem;
        margin-bottom: 5rem; 
    }

    ul {
        width: 100%;
        margin: 0 auto;
        list-style: none;
        display: block;

        li {
            font-size: 2rem;
            padding: 2% 0;
        }
    }
    
    @media screen and (min-width: 768px) {
        h3 {
            font-size: 6rem;
            margin-bottom: 5rem;
        }

        ul {
            li {
                font-size: 3.5rem;
                padding: 2rem;
            }
        }
    }

    @media screen and (min-width: 992px) {
    }

    @media screen and (min-width: 1200px) {
        ul {
            display: flex;
            justify-content: space-between;

            li {
                padding: 3rem;
            }
        }
    }

`;

export const StyledSvg = styled.svg`
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: top;
    width: 100%;
    opacity: 0.5;
    z-index: -1;
`;