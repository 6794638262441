import React, { useState } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import { motion } from 'framer-motion';
import { hmbgrTop, hmbgrMiddle, hmbgrBottom } from '../anims/SideNavAnimations';

const Nav = () => {

    const [menuIsShown, toggleMenu] = useState(false);

    return (
        <NavBar className="menu">
            <div className="logo">
                <StyledSvg viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H323.81L400 45.933H76.1905L0 0Z" fill="#3A3A3A"/>
                    <path d="M0 354.067L76.1905 400L221.49 361.449L323.81 253.178L400 112.645L323.81 66.7122L250.061 207.245L146.52 315.516L0 354.067Z" fill="#3A3A3A"/>
                    <path d="M0 0H323.81L400 45.933H76.1905L0 0Z" stroke="#C4C4C4"/>
                    <path d="M0 354.067L76.1905 400L221.49 361.449L323.81 253.178L400 112.645L323.81 66.7122L250.061 207.245L146.52 315.516L0 354.067Z" stroke="#C4C4C4"/>
                </StyledSvg>
                <a href="#home">
                    &nbsp;Felipe Pulido
                </a>
            </div>
            <Hamburger
                onClick={() => toggleMenu(!menuIsShown)} >
                <motion.div className="top" 
                    variants={hmbgrTop} 
                    initial={false} 
                    animate={menuIsShown ? "end" : "start"} />
                <motion.div className="middle" 
                    variants={hmbgrMiddle} 
                    initial={false} 
                    animate={menuIsShown ? "end" : "start"} />
                <motion.div className="bottom" 
                    variants={hmbgrBottom} 
                    initial={false} 
                    animate={menuIsShown ? "end" : "start"} />
            </Hamburger>
            <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
            <SideNav menuIsShown={menuIsShown} toggleMenu={toggleMenu}/>
        </NavBar>
    );
}

const StyledSvg = styled.svg`
    width: 20px;

    @media screen and (min-width: 768px) {
        width: 30px;
    }

    @media screen and (min-width: 992px) {
        /* width: 50px; */
    }
`;

const Hamburger = styled(motion.div)`
    max-width: 30px;
    flex: 0.2;
    z-index: 2;
    cursor: pointer;

    div {
        height: 3px;
        background: #3A3A3A;
        margin: 4px 0px;
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
`;

const NavBar = styled.nav`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 1vh 2.5vw;
    min-height: 5vh;
    position: sticky;
    top: 0;
    z-index: 1;

    .logo {
        display: flex;
        align-items: center;
        flex: 3;

        a {
            display: inline-flex;
        }
    }

    ul {
        display: none;
        flex: 1;
        list-style-type: none;
        justify-content: space-between;
        align-items: center;

        li {
            display: inline-flex;
            text-decoration: none;

            a {
                position: relative;
                padding: 0.2rem;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                    height: 3px;
                    background-color: $textPrimaryColor;
                    transition: 0.3s;
                }

                &:hover {
                    &::after {
                        content: '';
                        position: absolute;
                        left: -25%;
                        width: 150%;
                        background-color: $textPrimaryColor;
                        transform: rotateZ(-5deg);
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        padding: 1vh 1vw;
        font-size: 1.3rem;

        ul {
            display: flex;
            flex: 2;
        }
    }

    @media screen and (min-width: 992px) {
        ul {
            flex: 1;
        }
    }

    @media screen and (min-width: 1900px) {
        
        .logo {
            /* width: 70%; */
        }
    }
`;
export default Nav;