import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { revealFromUpperRight } from '../anims/SideNavAnimations';
import { fade } from '../anims/GlobalAnimations';


const SideNav = ({menuIsShown, toggleMenu}) => {
    return (
        <StyledSideNav variants={revealFromUpperRight} initial={false} animate={menuIsShown ? "end" : "start"}>
            <Anchor onClick={() => toggleMenu(!menuIsShown)} variants={fade} href="#home">Home</Anchor>
            <Anchor onClick={() => toggleMenu(!menuIsShown)} variants={fade} href="#about">About</Anchor>
            <Anchor onClick={() => toggleMenu(!menuIsShown)} variants={fade} href="#contact">Contact</Anchor>
        </StyledSideNav>
    );
}

const StyledSideNav = styled(motion.div)`
    width: 50%;
    background: #3A3A3A;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 100;
    min-height: 30vh;
    z-index: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    border-radius: 10px 0 10px 10px;
`;

const Anchor = styled(motion.a)`
    color: rgb(200,200,200);
`;

export default SideNav;