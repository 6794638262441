export const fade = {
    start: (duration=0.3) => ({
        opacity: 0,
        transition: {
            duration: duration,
            type: "tween",
            ease: "easeOut",
            staggerChildren: 0.1
        }
    }),
    end: (duration=0.3) => ({
        opacity: 1,
        transition: {
            duration: duration,
            type: "tween",
            ease: "easeIn",
            staggerChildren: 0.1
        }
    })
};

export const rollDown = {
    start: {
        y: -50,
        opacity: 0,
        transition: {
            duration: 1,
            type: "spring",
            staggerChildren: 0.1
        }
    },
    end: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: "spring",
            staggerChildren: 0.1
        }
    }
};

export const rollUp = {
    start: {
        y: 50,
        opacity: 0,
        transition: {
            duration: 1,
            type: "spring",
            staggerChildren: 0.1
        }
    },
    end: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: "spring",
            staggerChildren: 0.1
        }
    }
};