import React from 'react';
import useScroll from '../hooks/useScroll';
import { fade } from '../anims/GlobalAnimations';
import { Contact, GIT, StyledSvg } from '../styles/ContactStyles';
import { motion } from 'framer-motion';

const ContactSection = () => {

    const [element, controls] = useScroll();

    return ( 
        <Contact id="contact" className="contact">
            <StyledSvg viewBox="0 1 2245 532" xmlns="http://www.w3.org/2000/svg">
                <path d="M2244.5 30.4998C1927.36 30.4998 2109 304.5 1594.5 109.637C1385.55 30.4998 1414 211 1207 211C923.363 211 739.5 401 395.5 236.5C395.5 236.5 150.5 109.637 0.999995 251L0.999974 1.00023L2244.5 1.00004L2244.5 30.4998Z" fill="#828282"/>
            </StyledSvg>
            <StyledSvg viewBox="0 1 2245 568" xmlns="http://www.w3.org/2000/svg">
                <path d="M2244.5 61C2177.5 143 1883 174.484 1716 97C1195.5 -144.5 1216.5 395.5 764.5 244.5C658 181.5 225 -31 1 314L1.00003 1.00006L2244.5 1.00026V61Z" fill="#3A3A3A"/>
            </StyledSvg>
            <GIT variants={fade} ref={element} initial="hidden" animate={controls}>
                <h3>Get in touch!</h3>
                <ul>
                    <motion.li whileHover={{ scale: 1.5 }} whileTap={{ scale: 0.9 }}><a href="https://twitter.com/felipejpm" target="_blank" rel="noopener noreferrer">Twitter</a></motion.li>
                    <motion.li whileHover={{ scale: 1.5 }} whileTap={{ scale: 0.9 }}><a href="https://www.instagram.com/felipe_pulido/" target="_blank" rel="noopener noreferrer">Instagram</a></motion.li>
                    <motion.li whileHover={{ scale: 1.5 }} whileTap={{ scale: 0.9 }}><a href="https://github.com/Fetrelo" target="_blank" rel="noopener noreferrer">Github</a></motion.li>
                </ul>
            </GIT>
        </Contact>
    );
}

export default ContactSection;