export const revealFromUpperRight = {
    start: () => ({
        clipPath: `circle(20px at 100% -20px)`,
        transition: {
            ease: "easeIn",
            type: "spring",
            stiffness: 400,
            staggerChildren: 0.1,
            damping: 35
        }
    }),
    end: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 100% 0px)`,
        transition: {
            duration: 0.3,
            ease: "easeIn",
            type: "spring",
            stiffness: 20,
            restDelta: 2,
            staggerChildren: 0.1,
        }
    })
};

export const hmbgrTop = {
    start: {
        background: "rgb(58,58,58)",
        transform: 'translateY(0px) rotate(0deg)'
    },
    end: {
        background: "rgb(200,200,200)",
        transform: 'translateY(7px) rotate(-45deg)'
    }
}

export const hmbgrMiddle = {
    start: {
        background: "rgb(58,58,58)",
        opacity: 1,
        transition: {
            duration: 0.1
        }
    },
    end: {
        background: "rgb(200,200,200)",
        opacity: 0,
        transition: {
            duration: 0.1
        }
    }
}

export const hmbgrBottom = {
    start: {
        background: "rgb(58,58,58)",
        transform: 'translateY(0px) rotate(0deg)'
    },
    end: {
        background: "rgb(200,200,200)",
        transform: 'translateY(-7px) rotate(45deg)'
    }
}