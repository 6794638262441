//import logo from './logo.svg';
import React from 'react';
import Nav from './components/Nav';
import HomeSection from './components/HomeSection';
import AboutSection from './components/AboutSection';
import ContactSection from './components/ContactSection';

function App() {

  return (
    <>
      <Nav/>
      <HomeSection />
      <AboutSection />
      <ContactSection />
    </>
  );
}

export default App;
