import React from 'react';
// import gdl7 from '../img/gdl7.jpg';
// import gdl8 from '../img/gdl8.jpg';
// import gdl9 from '../img/gdl9.jpg';
// import gdl17 from '../img/gdl17.jpg';
// import gdl18 from '../img/gdl18.jpg';
// import gdl19 from '../img/gdl19.jpg';
// import gdl20 from '../img/gdl20.jpg';
import gdl22 from '../img/gdl22.jpg';
// import gdlrain from '../img/gdlrain.jpg';

import { About, Wrapper, Description, Image } from '../styles/AboutStyles';
import useScroll from '../hooks/useScroll';
import { motion } from "framer-motion";
import { fade } from '../anims/GlobalAnimations';
import Swal from 'sweetalert2';

const AboutSection = () => {

    const [element, controls ] = useScroll({threshold: 0.2});

    const swalert = () => {
        Swal.fire({
            title: 'Hehe!',
            text: "He'll be adding some projects soon...",
            icon: 'warning',
            confirmButtonText: 'Cool'
        });
    }

    return ( 
        <About id="about">
            <Wrapper ref={element} variants={fade} initial="start" animate={controls}>
                <Description>
                    <p>A web &amp; mobile developer based in Guadalajara, Mexico. Always looking for different and newer ways to improve his coding skills.</p>
                    <p>When he's not coding... He might be hanging out with his family/friends, doing sports, 3D printing, or just enjoying a good series/movie.</p>
                    <p>Click <span onClick={swalert}>here</span> to see a bit of his work.</p>
                </Description>
                <Image>
                    <motion.img initial={{ scale: 1.1 }} whileHover={{ scale: 1.5 }} alt="Guadalajara" src={gdl22} />
                </Image>
            </Wrapper>
        </About>
    );
}

export default AboutSection;