import styled from 'styled-components';
import { motion } from 'framer-motion';

export const About = styled(motion.section)`
    justify-content: space-between;
    padding: 5rem 2rem;
    background: #767676;
    position: relative;
`;

export const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1200px) {
        flex-direction: row;
        align-items: center;
    }

    @media screen and (min-width: 1900px) {
    }
`;

export const Description = styled.div`
    p {
        font-size: 1.5em;
        margin: 2vh 0vh;
        color: rgb(200,200,200);
        text-align: justify;

        span {
            cursor: pointer;
            color: #3A3A3A;
        }
    }

    h2 {
        font-weight: lighter;
    }

    @media screen and (min-width: 768px) {
        p {
            font-size: 2em;
            margin: 3vh 0vh 2.1vh;
        }
    }

    @media screen and (min-width: 992px) {
    }

    @media screen and (min-width: 1200px) {
        flex: 1;

        p { margin: 3vh; }
    }

    @media screen and (min-width: 1900px) {
    }
`;

export const Image = styled.div`
    flex: 1;
    overflow: hidden;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.5);
    margin: auto;

    img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
    }

    @media screen and (min-width: 768px) {
        img { height: 40vh; }
    }

    @media screen and (min-width: 992px) {
        margin: 0 1vh;
        img { height: 50vh; }
    }

    @media screen and (min-width: 1200px) {
        flex: 1;
    }

    @media screen and (min-width: 1900px) {
        img { height: 60vh; }
    }
`;

export const Hide = styled.div`
    overflow: hidden;
`;